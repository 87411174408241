import React from 'react'
import styled from 'styled-components';

const ExternalContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
`

const InternalContainer = styled.div`
  margin-right: 25px;
  margin-left: 25px;
  text-align: center;
  width: 100%;

  @media(min-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    max-height: 100vh;
  } 
`

export default function Container ({ children }) {
  return (
    <ExternalContainer key="external">
      <InternalContainer key="internal">
        {children}
      </InternalContainer>
    </ExternalContainer>
  )
}
