import React from 'react'
import styled from 'styled-components'

import PlayerButton from './PlayerButton';
import ConnectionStatus from './ConnectionStatus'
import Button from './Button'
import Container from './Container';

const TopIcon = styled.img`
  height: 55px;
  margin-top: 25px;
`

const FullButton = styled(Button)`
  width: 100%;

  @media(min-width: 1024px) {
    height: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    max-height: 25vh;
  }
`

const TasksButton = styled(FullButton)`
  background-color: #e8a212;

  &:hover {
    background-color: #eab64b;
  }

  &:disabled {
    background-color: #ebdea7;
  }
`

const DiscussionButton = styled(FullButton)`
  background-color: #e81212;

  &:hover {
    background-color: #f36363;
  }

  &:disabled {
    background-color: #f1a5a5;
  }
`

const RefreshButton = styled(FullButton)`
  background-color: #eee;

  &:hover {
    background-color: #ddd;
  }

  height: 120px;
`

const HalfButton = styled(Button)`
  width: calc(50% - 5px);
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media(min-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`

const PlayerContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: space-around;

  @media(min-width: 1024px) {
    flex-grow: 1;
    align-items: stretch;
    flex-direction: row;
  }
`

const TopBar = styled.div`
  align-items: center;
`

const HalfSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media(min-width: 1024px) {
    width: calc(50vw - 40px);
    margin: 20px;
  }
`

function GamePage ({ gameState, sendStageUpdate, sendRefresh, sendAliveUpdate, loading, reconnecting }) {
  return (
    <Container>
      <HalfSection>
          <TopBar>
            <TopIcon src='icon.png' alt='Amongcord Icon'/>
          </TopBar>
          <ConnectionStatus reconnecting={reconnecting} channelName={gameState.channel_name} />
          <FullButton disabled={gameState.game_stage === 'lobby' || loading} onClick={() => { sendStageUpdate('lobby') }}>Lobby</FullButton><br/>
          <FlexContainer>
            <TasksButton disabled={gameState.game_stage === 'tasks' || loading} onClick={() => { sendStageUpdate('tasks') }}>Tasks</TasksButton>
            <DiscussionButton disabled={gameState.game_stage === 'discussion' || loading} onClick={() => { sendStageUpdate('discussion') }}>Discussion</DiscussionButton>
            <RefreshButton onClick={() => { sendRefresh() }}>Refresh</RefreshButton>
          </FlexContainer>
      </HalfSection>
      <HalfSection>
        <h2>Players</h2>
        <PlayerContainer>
          {gameState.players.map(p => (
            <PlayerButton player={p} loading={loading} sendAliveUpdate={sendAliveUpdate} />
          ))}
        </PlayerContainer>
      </HalfSection>
    </Container>
  );
}

export default GamePage;
